import React , {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'; 

const PrivateDatasetWarning = ({addToHistory, goBackInHistory}) => {
    useEffect(() => {
        addToHistory('/privateDataset');
        document.title = `Датасеты: Ошибка`;
      }, []);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-2);
    };
        return (
            <div id='unsuccessUpload'>
                <div id='mainSectionUnsuccess'>
                    <h2>Ошибка 404.</h2>
                    <div className='row'>
                        <button className='lightBlueButton' onClick={()=>{navigate(-2)}}>Назад</button>
                        <button className='blueButton' onClick={()=>{navigate('/')}}>На главную</button>
                    </div>
                </div>
            </div>
        ) 
}

export default PrivateDatasetWarning