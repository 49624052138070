import { create } from "zustand";

export const useLoginStore = create((set) => ({
  loggedIn: localStorage.getItem("accessToken"),
  user: JSON.parse(localStorage.getItem("user")) || null,

  login: (user) => {
    localStorage.setItem("user", JSON.stringify(user)); // Сохраняем пользователя
    set({
      loggedIn: true,
      user,
    });
  },

  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    set({
      loggedIn: false,
      user: null,
    });
  },
}));
