import React, { useEffect, useState } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem('cookieConsent', 'true');
  };

  const handleReject = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleModalReject = () => {
    setIsModalVisible(false);
    setIsVisible(false);
  };

  if (localStorage.getItem('cookieConsent') === 'true') return null;

  return (
    <>
        <div id='cookie-consent-layer'>
            <div className="cookie-consent">
                <p>
                    Используя данный сайт, я даю своё согласие на использование файлов cookie, а также подтверждаю, что ознакомился с{' '}
                    <a href="/privacyPolicy" target="_blank" rel="noopener noreferrer">Политикой конфиденциальности</a>.
                </p>
                <div className="buttons">
                    <button className="blueButton" id='acceptCookieButton' onClick={handleAccept}>Принять</button>
                    <button className="whiteBlueButton" id='rejectCookieButton' onClick={handleReject}>Отклонить</button>
                </div>
            </div>
        </div>

      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-cookie">
            <button className="modal-close-cookie" onClick={handleModalClose}>&times;</button>
            <p className='bigLineHeight'>Файлы cookie необходимы для корректной работы сайта.</p>
            <p className='bigLineHeight'>Если вы не согласны на их использование, мы рекомендуем изменить настройки вашего браузера для управления файлами cookie или покинуть сайт.</p>
            <p className='bigLineHeight'>Без их использования некоторые функции сайта могут быть недоступны.</p>
            <div className="modal-buttons">
                <button className="blueButton" id='acceptCookieButton' onClick={handleAccept}>Принять</button>
                <button className="whiteBlueButton" id='rejectCookieButton' onClick={handleModalReject}>Отклонить</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
