import React, { useState, useEffect } from 'react';
import './FileList.css';
import { Files } from '../../components';

const FileList = ({ filesStructure, isVisible, setIsVisible, size, numberOfFiles }) => {
    const [opacity, setOpacity] = useState(1);
    const [mouseOver, setMouseOver] = useState(false);
    const [fadeOutTimeout, setFadeOutTimeout] = useState(null);

    const handleClose = () => {
        setIsVisible(false);
    };

    const getFileCountString = (files_structure) => {
        const getFilesAndFoldersCount = (files_structure) => {
            const folderCount = Object.keys(files_structure).filter(
                (key) =>
                    typeof files_structure[key] === "object" &&
                    files_structure[key] !== null
            ).length;

            let totalFileCount = 0;
            Object.entries(files_structure).forEach(([key, value]) => {
                if (typeof value === "object" && value !== null) {
                    totalFileCount += Object.keys(value).length;
                } else {
                    totalFileCount += 1;
                }
            });
            return { folders: folderCount, files: totalFileCount };
        };

        const getFolderWord = (count) => {
            if (count % 10 === 1 && count % 100 !== 11) return "папка";
            else if (
                count % 10 >= 2 &&
                count % 10 <= 4 &&
                (count % 100 < 10 || count % 100 >= 20)
            )
                return "папки";
            else return "папок";
        };

        const getFileWord = (count) => {
            if (count % 10 === 1 && count % 100 !== 11) return "файл";
            else if (
                count % 10 >= 2 &&
                count % 10 <= 4 &&
                (count % 100 < 10 || count % 100 >= 20)
            )
                return "файла";
            else return "файлов";
        };

        const counts = getFilesAndFoldersCount(files_structure);
        if (counts.folders === 0)
            return `${counts.files} ${getFileWord(counts.files)}`;
        return `${counts.folders} ${getFolderWord(counts.folders)}, ${
            counts.files
        } ${getFileWord(counts.files)}`;
    };

    const startFadeOut = () => {
        const fadeTimer = setInterval(() => {
            setOpacity((prevOpacity) => {
                if (prevOpacity <= 0.05) {
                    clearInterval(fadeTimer);
                    setIsVisible(false);
                    return 0;
                }
                return prevOpacity - 0.01;
            });
        }, 50);
    };

    // useEffect(() => {
    //     if (!isVisible) return;

    //     // Очистка предыдущего таймера, если он существует
    //     if (fadeOutTimeout) clearTimeout(fadeOutTimeout);

    //     // Устанавливаем fadeOut таймер, если мышь не наведена
    //     const timeout = setTimeout(() => {
    //         if (!mouseOver) startFadeOut();
    //     }, 5000);

    //     setFadeOutTimeout(timeout);

    //     return () => clearTimeout(timeout);
    // }, [isVisible, mouseOver]);

    const handleMouseEnter = () => {
        setMouseOver(true);
        setOpacity(1); // Устанавливаем непрозрачность мгновенно
        if (fadeOutTimeout) clearTimeout(fadeOutTimeout); // Очищаем fadeOut таймер
    };

    const handleMouseLeave = () => {
        setMouseOver(false);

        // Устанавливаем таймер на fadeOut только через 5 секунд после ухода мыши
        const timeout = setTimeout(() => {
            if (!mouseOver) startFadeOut();
        }, 5000);

        setFadeOutTimeout(timeout);
    };

    if (!isVisible) return null;

    return (
        <div
            className="file-list-container"
            style={{
                padding: '10px 15px 0px 15px',
                transition: 'opacity 0.5s ease', // Плавный переход для opacity
            }}
        >
            <div>
                <div
                    id={
                        numberOfFiles === 0
                            ? "filesHeader"
                            : "filesHeaderWithBottomDivider"
                    }
                    className="rowSpaceBetween"
                    style={{ padding: '5px 10px 15px 20px', alignItems: 'baseline' }}
                >
                    <div className='row'>
                        <p className="author" style={{ marginRight: '15px' }}>Скачано ({getFileCountString(filesStructure)})</p>
                        <p>{size}</p>
                    </div>
                    <button
                        className="modal-close-button"
                        onClick={handleClose}
                        style={{ fontSize: '30px', lineHeight: '20px', padding: '0', marginLeft: '15px' }}
                    >
                        &times;
                    </button>
                </div>
                <Files filesStructure={filesStructure} />
            </div>
        </div>
    );
};

export default FileList;
