import React, { useState } from 'react';
import './ShareDropdown.css';
import arrowBlue from '../../img/arrowBlue.png'
import linkBlue from '../../img/linkBlue.png'

const ShareDropdown = ({ link }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
    console.log(link)
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="dropdown-container">
      <button onClick={toggleDropdown} className="share-button">
        Поделиться <img src={arrowBlue} className={!isDropdownOpen ? "shareButtonArrow" : "shareButtonArrow rotate180"} />
      </button>

      {isDropdownOpen && (
        <div className="dropdown-menu">
          <div className="link-status" onClick={handleCopyLink}>
            {isCopied ? 'Ссылка скопирована!' : 'Скопировать ссылку'}
            {!isCopied && <img src={linkBlue} className="linkIcon" />}
          </div>

          <div className='blueDivider'></div>

          <div className="info-text">
            Все, у кого есть ссылка, могут посмотреть, скачать датасет
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareDropdown;
