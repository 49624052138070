import { useLoginStore } from "./useLoginStore";
import { BackendConnector } from "../components";

const ADFS_LOGIN_URL = process.env.REACT_APP_ADFS_LOGIN_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

async function handleLogin(code, redirectToLanding) {
  try {
    const tokenInfo = await BackendConnector.getToken(code); // Используем BackendConnector
    if (tokenInfo) {
      handleLoginTokens(tokenInfo);
      queueMicrotask(redirectToLanding);
    } else {
      throw new Error("Failed to retrieve token");
    }
  } catch (error) {
    console.error("Failed to log in. Please try again. Error in handleLogin:", error);
  }
}

function handleADFSLogin() {
  const params = new URLSearchParams({
    response_type: "code",
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    scope: "openid",
    state: "state_value",
  });
  window.location.href = `${ADFS_LOGIN_URL}?${params.toString()}`;
}

export function handleLoginTokens(tokenInfo) {
  localStorage.setItem("accessToken", tokenInfo.access_token);
  localStorage.setItem("refreshToken", tokenInfo.refresh_token);

  const user = { email: tokenInfo.email || "Личный кабинет" };
  useLoginStore.getState().login(user);
}

export { handleADFSLogin, handleLogin };
