import React, { useState, useEffect } from 'react'
import { DatasetCard, Back, Filters, BackendConnector, Alert } from '../../components'
import './Datasets.css'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import searchIcon from '../../img/search.png'
import _ from 'lodash';
import arrowBlue from '../../img/arrowBlue.png';

const Datasets = ({addToHistory, back, defaultSize, defaultRate}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [sortedData, setSortedData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('byRelevance');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [datasets, setDatasets] = useState([]);

    const [searchString, setSearchString] = useState(queryParams.get('searchString') || '');
    const [geography_and_places, setGeography] = useState([]);
    const [data_type, setData_type] = useState([]);
    const [topic, setTopic] = useState([]);
    const [applications, setApplications] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [sources, setSources] = useState([]);
    const [license, setLicense] = useState([]);
    const [sizeLower, setSizeLower] = useState(Number(queryParams.get('sizeLower')));
    const [sizeUpper, setSizeUpper] = useState(Number(queryParams.get('sizeUpper')));
    const [dateLower, setDateLower] = useState(Number(queryParams.get('dateLower')) || "");
    const [dateUpper, setDateUpper] = useState(Number(queryParams.get('dateUpper')) || "");
    const [rateLower, setRateLower] = useState(Number(queryParams.get('rateLower')));
    const [rateUpper, setRateUpper] = useState(Number(queryParams.get('rateUpper')));

    const [warningSearch, setwarningSearch] = useState(false);
    const [alertState, setalertState] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [noDatasets, setnoDatasets] = useState(false);
    const [blueButton, setblueButton] = useState('');
    const [lightBlueButton, setlightBlueButton] = useState('');
    
    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true);

    const setQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
    
        const newSearchString = queryParams.get('searchString') || '';
        const newGeography = queryParams.get('geography_and_places') ? queryParams.get('geography_and_places').split(',') : [];
        const newDataType = queryParams.get('data_type') ? queryParams.get('data_type').split(',') : [];
        const newTopic = queryParams.get('topic') ? queryParams.get('topic').split(',') : [];
        const newApplications = queryParams.get('applications') ? queryParams.get('applications').split(',') : [];
        const newAuthors = queryParams.get('authors') ? queryParams.get('authors').split(',') : [];
        const newSources = queryParams.get('sources') ? queryParams.get('sources').split(',') : [];
        const newLicense = queryParams.get('license') ? queryParams.get('license').split(',') : [];
        const newSizeLower = queryParams.get('sizeLower');
        const newSizeUpper = queryParams.get('sizeUpper');
        const newRateLower = queryParams.get('rateLower');
        const newRateUpper = queryParams.get('rateUpper');
        const newDateLower = queryParams.get('dateLower');
        const newDateUpper = queryParams.get('dateUpper');
    
        setSearchString(newSearchString);
        setGeography(newGeography);
        setData_type(newDataType);
        setTopic(newTopic);
        setApplications(newApplications);
        setAuthors(newAuthors);
        setSources(newSources);
        setLicense(newLicense);
        setSizeLower(Number(newSizeLower));
        setSizeUpper(Number(newSizeUpper));
        setRateLower(newRateLower);
        setRateUpper(newRateUpper);
        setDateLower(newDateLower);
        setDateUpper(newDateUpper);
    
        return {
            newSearchString,
            newGeography,
            newDataType,
            newTopic,
            newApplications,
            newAuthors,
            newSources,
            newLicense,
            newSizeLower,
            newSizeUpper,
            newRateLower,
            newRateUpper,
            newDateLower,
            newDateUpper,
        };
    };
    
    const handleClick = async (e) => {
        if (e?.preventDefault) e.preventDefault();
        const queryParams = new URLSearchParams({
            searchString: searchString,
            geography_and_places: geography_and_places.join(','),
            data_type: data_type.join(','),
            topic: topic.join(','),
            applications: applications.join(','),
            authors: authors.join(','),
            sources: sources.join(','),
            license: license.join(','),
            sizeLower: sizeLower, sizeUpper: sizeUpper,
            dateLower: dateLower, dateUpper: dateUpper,
            rateLower: rateLower, rateUpper: rateUpper,
        }).toString();
        if (queryParams == new URLSearchParams(location.search)) return;
        addToHistory(`/datasets?${queryParams}`);
        setLoading(true)
        navigate(`/datasets?${queryParams}`);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') handleClick(event);
    };

    const search = async (e) => {
        if (e) e.preventDefault();
    
        const {
            newSearchString,
            newGeography,
            newDataType,
            newTopic,
            newApplications,
            newAuthors,
            newSources,
            newLicense,
            newSizeLower,
            newSizeUpper,
            newRateLower,
            newRateUpper,
            newDateLower,
            newDateUpper,
        } = setQueryParams();
    
        setnoDatasets(false);
    
        try {
            const filters = {
                tags: {
                    geography_and_places: newGeography,
                    data_type: newDataType,
                    topic: newTopic,
                    applications: newApplications
                },
                filters: {
                    authors: newAuthors,
                    data_source: newSources,
                    license: newLicense,
                    size_lower_bound: newSizeLower.toString(),
                    size_upper_bound: newSizeUpper.toString(),
                    rating_lower_bound: newRateLower.toString(),
                    rating_upper_bound: newRateUpper.toString(),
                    date_lower_bound: newDateLower.toString(),
                    date_upper_bound: newDateUpper.toString(),
                }
            };
    
            const data = await BackendConnector.search(newSearchString, filters, 100);
    
            if (data && data.length > 0) {
                setDatasets(data);
                setSortedData(data);
            } else {
                setnoDatasets(true);
            }
            setLoading(false)
        } catch (error) {
            setalertState(true);
            setErrorMessage(error.message || error.toString());
            setblueButton('Подождать');
            setlightBlueButton('Попробовать ещё раз');
        }
    };
    
    useEffect(()=>{
        setQueryParams();
    }, [])

    useEffect(() => {
        setIsModalOpen(false)
        search()
        const timer = setTimeout(() => {
            setFirstLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [location.search]);

    useEffect(() => {
        let sorted = [...datasets];
        switch (selectedOption) {
            case 'byRating':
                sorted = _.orderBy(datasets, ['rating'], ['desc']);
                break;
            case 'largeFirst':
                sorted = _.orderBy(datasets, ['size_bytes'], ['desc']);
                break;
            case 'smallFirst':
                sorted = _.orderBy(datasets, ['size_bytes'], ['asc']);
                break;
            default:
                sorted = [...datasets];
        }
        setSortedData(sorted);
    }, [datasets, selectedOption]);
    
    const [size, setSize] = useState(null);
    const [rate, setRate] = useState(null);
    const [date, setDate] = useState(null);

    function valueLabelFormat(s) {
        if (s === 0) return '0 Б';
        const sizes = ['Б', 'КБ', 'МБ', 'ГБ'];
        const i = Math.floor(Math.log(s) / Math.log(1024));
        const formattedSize = (s / Math.pow(1024, i)).toFixed(3);
        return `${parseFloat(formattedSize)} ${sizes[i]}`;
    };

    useEffect(() => {
        if (dateLower == "" && dateUpper == "") setDate(null);
        else if (dateLower === dateUpper)
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен {dateLower}
                    <button id="deleteTagButton" onClick={()=>{setDateLower("")}}>&times;</button>
                </div>
            );
        else if (dateLower !== "" && dateUpper == ""){
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен после {dateLower}
                    <button id="deleteTagButton" onClick={()=>{setDateLower("")}}>&times;</button>
                </div>
            );
        }
        else if (dateLower == "" && dateUpper !== ""){
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен до {dateUpper}
                    <button id="deleteTagButton" onClick={()=>{setDateUpper("")}}>&times;</button>
                </div>
            );
        }
        else if (dateLower !== "" && dateUpper !== ""){
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен {dateLower} - {dateUpper}
                    <button id="deleteTagButton" onClick={()=>{
                        setDateLower("")
                        setDateUpper("")
                    }}>&times;</button>
                </div>
            );
        }
    }, [dateLower, dateUpper]);

    useEffect(() => {
        setSize(
            <div id="tagAdvancedSearch" className="lightBlueButton">
                Размер: {valueLabelFormat(sizeLower)} - {valueLabelFormat(sizeUpper)}
                <button id="deleteTagButton" onClick={deleteSizeFilter}>&times;</button>
            </div>
            );
    }, [sizeLower, sizeUpper]);
    
    const deleteSizeFilter = () => {
        setSizeLower(defaultSize[0]);
        setSizeUpper(defaultSize[1]);
    };
    
    useEffect(() => {
        setRate(
            <div id="tagAdvancedSearch" className="lightBlueButton">
                Рейтинг: {rateLower.toString()} - {rateUpper.toString()}
                <button id="deleteTagButton" onClick={deleteRateFilter}>&times;</button>
            </div>
        );
    }, [rateLower, rateUpper]);
    
    const deleteRateFilter = () => {
        setRateLower(defaultRate[0]); // Сбрасываем к начальным значениям
        setRateUpper(defaultRate[1]);
    };
     
  const handleRemoveTag = (arr, setArr, index) => {
    const newTags = arr.filter((_, i) => i !== index);
    setArr(newTags);
  };

  const toggleAdvancedSearch = () => {
    setIsModalOpen(!isModalOpen);
  }
    
    return (
        <div id='datasets'>
            <Back back={back}/>
            <div className='searchFiltersSort'>
                <div style={{width: '100%'}}>
                    <form id='inputSearchDatasets' onSubmit={handleClick} onKeyDown={handleKeyDown}>
                        <input
                            style={{width: '90%'}}
                            type='text'
                            id={warningSearch ? 'wrongInput' : 'correctInput'}
                            placeholder='Поиск по каталогу датасетов'
                            value={warningSearch? 'Введите запрос в поле или укажите фильтры' : searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            onClick={()=>{setwarningSearch(false)}}
                            maxLength={1000}
                        />
                            <select className="selectionInput" id='sort' value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                <option value="byRelevance">По релевантности</option>
                                <option value="byRating">По рейтингу</option>
                                <option value="largeFirst">Сначала большие</option>
                                <option value="smallFirst">Сначала маленькие</option>
                            </select>
                        <button type='submit' id='searchButton'>Найти</button>
                        <button type='submit' id='searchIcon'><img id='searchIcon' src={searchIcon} alt='search'/></button>
                    </form>
                    <div className="topicsList" style={{marginLeft: '0px'}}>
                    <button id="advancedSearch" style={{marginTop: '0px'}} onClick={()=>toggleAdvancedSearch()}>
                        <p>Продвинутый поиск</p>
                        {!isModalOpen && <img src={arrowBlue} />}
                        {isModalOpen && <img src={arrowBlue} style={{ transform: 'rotate(180deg)' }}/>}
                    </button>
                        {geography_and_places.map((tag, index) => (
                            <div id='tagAdvancedSearch' className={tag !== 'глобальная' ? 'capitalize lightBlueButton' : 'lightBlueButton'} key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(geography_and_places, setGeography, index)}>&times;</button>
                            </div>
                        ))}
                        {data_type.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(data_type, setData_type, index)}>&times;</button>
                            </div>
                        ))}
                        {topic.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(topic, setTopic, index)}>&times;</button>
                            </div>
                        ))}
                        {applications.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(applications, setApplications, index)}>&times;</button>
                            </div>
                        ))}
                        {authors.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(authors, setAuthors, index)}>&times;</button>
                            </div>
                        ))}
                        {sources.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(sources, setSources, index)}>&times;</button>
                            </div>
                        ))}
                        {license.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(license, setLicense, index)}>&times;</button>
                            </div>
                        ))}
                        {date}
                        {size}
                        {rate}
                    </div>
                    </div>
                <Alert message={"Ошибка: " + errorMessage} blueButton={blueButton} blueButtonFunc={()=>{setalertState(false)}} lightBlueButtonFunc={()=>{handleClick()}} lightBlueButton={lightBlueButton} isOpen={alertState} onClose={()=>{setalertState(false)}}/>
            </div>
            
            <Filters
                isOpen={isModalOpen}
                defaultSize={defaultSize}
                defaultRate={defaultRate}
                onClose={() => setIsModalOpen(false)}
                geography_and_places={geography_and_places}
                setGeography={setGeography}
                data_type={data_type}
                setData_type={setData_type}
                topic={topic}
                setTopic={setTopic}
                application={applications}
                setApplication={setApplications}
                authors={authors}
                setAuthors={setAuthors}
                sources={sources}
                setSources={setSources}
                license={license}
                setLicense={setLicense}
                sizeLower={sizeLower}
                setSizeLower={setSizeLower}
                sizeUpper={sizeUpper}
                setSizeUpper={setSizeUpper}
                dateLower={dateLower}
                setDateLower={setDateLower}
                dateUpper={dateUpper}
                setDateUpper={setDateUpper}
                rateLower={rateLower}
                setRateLower={setRateLower}
                rateUpper={rateUpper}
                setRateUpper={setRateUpper}
            />
            
            {((loading || firstLoading) && loading) ?
                <h4 style={{margin: '35px 0px 0px 6px'}}>Ищем датасеты...</h4>
                :
                <div id='cardsContainer'>
                    {!noDatasets ?
                        <div id='cards'>
                            {sortedData.map(dataset => (
                                <DatasetCard
                                    key={dataset.id}
                                    id={dataset.id}
                                    title={dataset.title}
                                    authors={dataset.authors}
                                    numberOfFiles={dataset.number_of_files}
                                    lastChangeDate={dataset.last_change_date}
                                    downloadsNumber={dataset.downloads_number}
                                    size={dataset.size}
                                    smallDescription={dataset.small_description}
                                    addToHistory={addToHistory}
                                />))}
                        </div>
                        :
                        <h3 style={{marginTop: '30px'}}>Ничего не удалось найти по Вашему запросу</h3>
                    }
                </div>}
        </div>
    );
}

export default Datasets;