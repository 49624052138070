import React, { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleADFSLogin, handleLogin } from "./Login.utils";
import { useLoginStore } from "./useLoginStore";
import { Menu } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";

const Login = ({ addToHistory, LOGGEDIN }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const userProfile = useLoginStore((state) => state.user);

  const redirectToLanding = () => {
    navigate("/");
  };

  const login = useCallback(
    (code) => handleLogin(code, redirectToLanding),
    [redirectToLanding]
  );

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) login(code);
  }, [searchParams]);

  const { loggedIn } = useLoginStore();

  const toUploadRequests = () => {
    addToHistory("/uploadRequests");
    navigate("/uploadRequests");
  };

  const toMain = () => {
    addToHistory("/");
    navigate("/");
  };

  const toUpload = () => {
    addToHistory("/upload");
    navigate("/upload");
  };

  const handleLogout = () => {
    useLoginStore.getState().logout();
  };

  if (!loggedIn) {
    return (
      <button
        className="whiteBlueButton"
        onClick={handleADFSLogin}
        style={{ padding: "8px 40px", marginRight: "30px" }}
      >
        Войти
      </button>
    );
  }

  return (
    <Menu
      width="target"
      trigger="hover"
      opened={opened}
      onChange={setOpened}
      classNames={{
        item: "menuItem",
        itemLabel: "menuItemLabel",
        dropdown: "menuDropdown",
      }}
    >
      <Menu.Target>
        <div className="userDropdown">
          {LOGGEDIN? 'Администратор' : userProfile?.email}
          <IconChevronDown />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={toMain}>На главную</Menu.Item>
        <Menu.Item onClick={toUpload}>Загрузить датасет</Menu.Item>
        <Menu.Item onClick={toUploadRequests}>Заявки на загрузку</Menu.Item>
        <Menu.Item onClick={handleLogout}>Выйти</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default Login;