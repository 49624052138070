import React, { useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollToTop";
import { Header, CookieConsent, BackendConnector } from "./components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Search,
  Upload,
  DatasetPage,
  Datasets,
  EditPage,
  UploadRequests,
  RequestPreview,
  ErrorPage,
  Error404,
  PrivacyPolicy,
  CookiePolicy
} from "./pages";

const datasetRestrictions = {
  doiLimit: 100,
  descriptionLimit: 5000,
  smallDescriptionLimit: 500,
  titleLimit: 200,
  authorsLimit: 500,
  sourceLimit: 100,
  frequencyLimit: 100,
  descriptionFieldsLimit: 3000,
};

const AppContent = ({ loggedIn, login, logout }) => {
  const [dateLastReset, setDateLastReset] = useState(() => {
    const storedDate = localStorage.getItem('lastResetDate');
    return storedDate ? new Date(storedDate) : null;
});

  useEffect(() => {
    const today = new Date();
    if (!dateLastReset || today.toDateString() !== dateLastReset.toDateString()) {
        setDateLastReset(today);
        localStorage.setItem('navigationHistory',[]);
        localStorage.setItem('lastResetDate', today.toISOString());
    }
  }, [dateLastReset]);

  const [historyStack, setHistoryStack] = useState(() => {
    const storedHistory = localStorage.getItem('navigationHistory');
    return storedHistory ? JSON.parse(storedHistory) : [];
  });

  const addToHistory = (path) => {
    setHistoryStack(prevHistory => {
      const updatedHistory = [...prevHistory, path];
      localStorage.setItem('navigationHistory', JSON.stringify(updatedHistory));
      return updatedHistory;
    });
  }

  const goBackInHistory = () => {
    if (historyStack.length > 1) {
      let lastIndex = historyStack.length - 2;
      let previousPath = historyStack[lastIndex];
      if (previousPath === '/editDataset') {
        const prevIndex = lastIndex;
        lastIndex = prevIndex - 1;
        previousPath = historyStack[lastIndex];
      }
      const updatedHistory = historyStack.slice(0, lastIndex+1);
      setHistoryStack(updatedHistory);
      localStorage.setItem('navigationHistory', JSON.stringify(updatedHistory));
      return previousPath;
    } else {
      return '/';
    }
  };

  // useEffect(()=>{
  //   console.log(historyStack);
  // }, [historyStack])
  const defaultSize = [0,5*2**30]
  const defaultRate = [0,10]

  const [LOGGEDIN, SETLOGGEDIN] = useState(localStorage.getItem('token') !== null);

  return (
    <>
      <ScrollToTop />
      <Header addToHistory={addToHistory} loggedin={loggedIn} LOGGEDIN={LOGGEDIN} SETLOGGEDIN={SETLOGGEDIN}/>
      <CookieConsent />
      <Routes>
        <Route path="/" element={<Search defaultSize={defaultSize} defaultRate={defaultRate} addToHistory={addToHistory}/>} />
        <Route path="/dataset/:id" element={<DatasetPage loggedin={LOGGEDIN} addToHistory={addToHistory} back={goBackInHistory} />} />
        <Route path="/upload" element={<Upload {...datasetRestrictions} addToHistory={addToHistory} back={goBackInHistory} />} />
        <Route path="/datasets" element={<Datasets defaultSize={defaultSize} defaultRate={defaultRate} addToHistory={addToHistory} back={goBackInHistory} />} />
        <Route path="/requestPreview/:request_id" element={<RequestPreview loggedin={LOGGEDIN} addToHistory={addToHistory} back={goBackInHistory} />} />
        <Route path="/uploadRequests" element={<UploadRequests loggedin={LOGGEDIN} addToHistory={addToHistory} back={goBackInHistory} />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/error404" element={<Error404 addToHistory={addToHistory} />}/>
        <Route path="/shared/:shared_token" element={<DatasetPage loggedin={LOGGEDIN} addToHistory={addToHistory} back={goBackInHistory}/>}/>
        <Route
          path="/editDataset"
          element={<EditPage {...datasetRestrictions} addToHistory={addToHistory} back={goBackInHistory} />}
        />
        <Route path='/privacyPolicy' element={<PrivacyPolicy addToHistory={addToHistory} back={goBackInHistory}/>} />
      </Routes>
    </>
  );
};

export default AppContent;
