import React, { useState, useEffect } from "react";
import "./Search.css";
import {
  Filters,
  BackendConnector,
  AuthAlert,
  CardsRowSection,
  PopularTopics,
} from "../../components";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../img/search.png";
import { handleADFSLogin } from "../../Login/Login.utils";
import arrowBlue from "../../img/arrowBlue.png";
import { useLoginStore } from "../../Login/useLoginStore.js";

const Search = ({addToHistory, defaultSize, defaultRate}) => {
    const auth = useLoginStore((state) => state.loggedIn);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [searchString, setSearchString] = useState('');
    const [datasets, setDatasets] = useState([]);
    const [noDatasets, setnoDatasets] = useState(false);
    const [warningSearch, setwarningSearch] = useState(false);
    const [authWarning, setauthWarning] = useState(false);

    const [geography_and_places, setGeography] = useState([]);
    const [data_type, setData_type] = useState([]);
    const [topic, setTopic] = useState([]);
    const [application, setApplication] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [sources, setSources] = useState([]);
    const [license, setLicense] = useState([]);
    const [sizeLower, setSizeLower] = useState(defaultSize[0]);
    const [sizeUpper, setSizeUpper] = useState(defaultSize[1]);
    const [rateLower, setRateLower] = useState(defaultRate[0]);
    const [rateUpper, setRateUpper] = useState(defaultRate[1]);
    const [dateLower, setDateLower] = useState("");
    const [dateUpper, setDateUpper] = useState("");

    const handleSearch = async (e) => {
        if (e) e.preventDefault();
        
        const queryParams = new URLSearchParams({
            searchString,
            geography_and_places: geography_and_places.join(','),
            data_type: data_type.join(','),
            topic: topic.join(','),
            applications: application.join(','),
            authors: authors.join(','),
            sources: sources.join(','),
            license: license.join(','),
            sizeLower: sizeLower, sizeUpper: sizeUpper,
            dateLower: dateLower, dateUpper: dateUpper,
            rateLower: rateLower, rateUpper: rateUpper,
        }).toString();
    
        addToHistory(`/datasets?${queryParams}`)
        navigate(`/datasets?${queryParams}`);
    };

    const handleTopicSearch = async (topic) => {
        const curQueryParams = new URLSearchParams({
            searchString: topic,
            geography_and_places: [],
            data_type: [],
            topic: [],
            application: [],
            authors: [],
            sources: [],
            license: [],
            sizeLower: defaultSize[0], sizeUpper: defaultSize[1],
            dateLower: "", dateUpper: "",
            rateLower: defaultRate[0], rateUpper: defaultRate[1]
        }).toString();
    
        addToHistory(`/datasets?${curQueryParams}`);
        navigate(`/datasets?${curQueryParams}`);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') handleSearch(event);
    };

    const topics = [
        'Классификация',
        'Фильмы',
        'Бизнес',
        'Эмоции',
        'Картинки',
        'Маркетинг',
        'Книги'
    ]

    const getHighlyRatedDatasets = async () => {
        try {
            const data = await BackendConnector.highly_rated_datasets(6);
            if (data && data.length > 0) setDatasets(data);
            else setnoDatasets(true);
        } catch (error) {
        }
    };

    const handleUploadClick = () => {
        if (!auth) setauthWarning(true);
        else {
            addToHistory('/upload')
            navigate('/upload', { state: { pageFrom: '/' } });
        }
    }
    
    useEffect(() => {
        addToHistory('/')
        document.title = `Поиск датасета`;
        getHighlyRatedDatasets();
    }, []);

    function valueLabelFormat(s) {
        if (s === 0) return '0 Б';
        const sizes = ['Б', 'КБ', 'МБ', 'ГБ'];
        const i = Math.floor(Math.log(s) / Math.log(1024));
        const formattedSize = (s / Math.pow(1024, i)).toFixed(3);
        return `${parseFloat(formattedSize)} ${sizes[i]}`;
    };
    
    const [size, setSize] = useState(null);
    const [rate, setRate] = useState(null);
    const [date, setDate] = useState(null);

    useEffect(() => {
        if (dateLower == "" && dateUpper == "") setDate(null);
        else if (dateLower === dateUpper)
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен {dateLower}
                    <button id="deleteTagButton" onClick={()=>{setDateLower("")}}>&times;</button>
                </div>
            );
        else if (dateLower !== "" && dateUpper == ""){
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен после {dateLower}
                    <button id="deleteTagButton" onClick={()=>{setDateLower("")}}>&times;</button>
                </div>
            );
        }
        else if (dateLower == "" && dateUpper !== ""){
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен до {dateUpper}
                    <button id="deleteTagButton" onClick={()=>{setDateUpper("")}}>&times;</button>
                </div>
            );
        }
        else if (dateLower !== "" && dateUpper !== ""){
            setDate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Обновлен {dateLower} - {dateUpper}
                    <button id="deleteTagButton" onClick={()=>{
                        setDateLower("")
                        setDateUpper("")
                    }}>&times;</button>
                </div>
            );
        }
    }, [dateLower, dateUpper]);

    useEffect(() => {
        if (sizeLower != defaultSize[0] || sizeUpper != defaultSize[1]) { // Если значения изменены от начальных
            setSize(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Размер: {valueLabelFormat(sizeLower)} - {valueLabelFormat(sizeUpper)}
                    <button id="deleteTagButton" onClick={deleteSizeFilter}>&times;</button>
                </div>
            );
        } else {
            setSize(null);
        }
    }, [sizeLower, sizeUpper]);
    
    const deleteSizeFilter = () => {
        setSizeLower(defaultSize[0]);
        setSizeUpper(defaultSize[1]);
    };
    
    useEffect(() => {
        if (rateLower != defaultRate[0] || rateUpper != defaultRate[1]) {
            setRate(
                <div id="tagAdvancedSearch" className="lightBlueButton">
                    Рейтинг: {rateLower.toString()} - {rateUpper.toString()}
                    <button id="deleteTagButton" onClick={deleteRateFilter}>&times;</button>
                </div>
            );
        } else {
            setRate(null);
        }
    }, [rateLower, rateUpper]);
    
    const deleteRateFilter = () => {
        setRateLower(defaultRate[0]); // Сбрасываем к начальным значениям
        setRateUpper(defaultRate[1]);
    };
    
  const handleRemoveTag = (arr, setArr, index) => {
    const newTags = arr.filter((_, i) => i !== index);
    setArr(newTags);
  };

  const toggleAdvancedSearch = () => {
    setIsModalOpen(!isModalOpen);
  }

    return (
        <div id='all'>
            <div id='search'>
                <div id='searchSection'>
                    <p id='main'>Датасеты</p>
                    <div id='searchRow' style={{width: '100%'}}>
                        <form id='inputSearch' onSubmit={handleSearch} onKeyDown={handleKeyDown}>
                            <div style={{width: '100%'}}>
                                <input type='text'
                                    id={warningSearch ? 'wrongInput' : 'correctInput'}
                                    placeholder='Поиск по каталогу датасетов'
                                    value={warningSearch? 'Введите запрос в поле или укажите фильтры' : searchString}
                                    onChange={(e) => setSearchString(e.target.value)}
                                    onClick={()=>{setwarningSearch(false)}}
                                />
                            </div>
                            <button type='submit' id='searchButton'>Найти</button>
                            <button type='submit' id='searchIcon'><img id='searchIcon' src={searchIcon} alt='search'/></button>
                        </form>
                        <button className='lightBlueButton' id='uploadDatasetButton' style={{padding: '9px 20px', textWrap: 'nowrap'}} onClick={()=>handleUploadClick()}>+ Новый датасет</button>
                        <button className='lightBlueButton' id='uploadIcon' style={{padding: '9px 20px', textWrap: 'nowrap'}} onClick={()=>handleUploadClick()}>+</button>
                        {authWarning && <AuthAlert onClose={()=>setauthWarning(false)} isOpen={authWarning}/>}
                    </div>
                </div>
                <div className="topicsList">
                    <button id="advancedSearch" style={{marginTop: '0px'}} onClick={()=>toggleAdvancedSearch()}>
                        <p>Продвинутый поиск</p>
                        {!isModalOpen && <img src={arrowBlue} />}
                        {isModalOpen && <img src={arrowBlue} style={{ transform: 'rotate(180deg)' }}/>}
                    </button>
                        {geography_and_places.map((tag, index) => (
                            <div id='tagAdvancedSearch' className={tag !== 'глобальная' ? 'capitalize lightBlueButton' : 'lightBlueButton'} key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(geography_and_places, setGeography, index)}>&times;</button>
                            </div>
                        ))}
                        {data_type.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(data_type, setData_type, index)}>&times;</button>
                            </div>
                        ))}
                        {topic.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(topic, setTopic, index)}>&times;</button>
                            </div>
                        ))}
                        {application.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(application, setApplication, index)}>&times;</button>
                            </div>
                        ))}
                        {authors.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(authors, setAuthors, index)}>&times;</button>
                            </div>
                        ))}
                        {sources.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(sources, setSources, index)}>&times;</button>
                            </div>
                        ))}
                        {license.map((tag, index) => (
                            <div id='tagAdvancedSearch' className='lightBlueButton' key={`${tag}-${index}`}>
                                {tag}
                                <button id='deleteTagButton' onClick={() => handleRemoveTag(license, setLicense, index)}>&times;</button>
                            </div>
                        ))}
                        {date}
                        {size}
                        {rate}
                </div>
                                            
                <Filters
                    isOpen={isModalOpen}
                    defaultSize={defaultSize}
                    defaultRate={defaultRate}
                    onClose={() => setIsModalOpen(false)}
                    geography_and_places={geography_and_places}
                    setGeography={setGeography}
                    data_type={data_type}
                    setData_type={setData_type}
                    topic={topic}
                    setTopic={setTopic}
                    application={application}
                    setApplication={setApplication}
                    authors={authors}
                    setAuthors={setAuthors}
                    sources={sources}
                    setSources={setSources}
                    license={license}
                    setLicense={setLicense}
                    sizeLower={sizeLower}
                    setSizeLower={setSizeLower}
                    sizeUpper={sizeUpper}
                    setSizeUpper={setSizeUpper}
                    dateLower={dateLower}
                    setDateLower={setDateLower}
                    dateUpper={dateUpper}
                    setDateUpper={setDateUpper}
                    rateLower={rateLower}
                    setRateLower={setRateLower}
                    rateUpper={rateUpper}
                    setRateUpper={setRateUpper}
                    applyChanges={handleSearch}
                />
                <CardsRowSection addToHistory={addToHistory} datasets={datasets} label='Высокий рейтинг'/>   
                <PopularTopics addToHistory={addToHistory} topics={topics} showAllClick={handleTopicSearch}/>         
            </div>
        </div>
    );
}

export default Search;
