import React from "react";
import "./App.css";
import AppContent from "./AppContent";
import { BackendConnector, NotificationProvider } from "./components";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { useLoginStore } from "./Login/useLoginStore";
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from "react";

const App = () => {
  const { loggedIn, login, logout } = useLoginStore();

  useEffect(() => {
    const initializeAuth = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (!accessToken && !refreshToken) {
        logout(); // Если токенов нет, разлогинить пользователя
        return;
      }

      // Проверить срок действия токена
      const expiry = parseInt(localStorage.getItem("authTokenExpiry"), 10);
      if (expiry && Date.now() > expiry) {
        // Если токен истёк, обновляем его
        const newToken = await BackendConnector.refreshToken();
        if (!newToken) {
          logout();
        }
      } else if (accessToken) {
        // Если токен ещё действителен
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) login(user);
      }
    };

    initializeAuth();
  }, [login, logout]);

  return (
    <MantineProvider>
      <NotificationProvider>
        <BrowserRouter>
          <AppContent loggedIn={loggedIn} login={login} logout={logout} />
        </BrowserRouter>
      </NotificationProvider>
    </MantineProvider>
  );
};

export default App;
