import React, { useState, useEffect } from 'react';
import { Stages, BackendConnector, DeleteVerification, RequestStatus } from '../..';
import { useNavigate } from 'react-router-dom';
import './UploadRequest.css';
import redTrash from '../../../img/trashRed.png';
import arrowDown from '../../../img/arrowDown.png';
import not_started from '../../../img/notStartedStage.png';
import in_progress from '../../../img/startedStage.png';
import failed from '../../../img/failStage.png';
import done from '../../../img/successStage.png';
import editIcon from '../../../img/editBlue.png';
import crossRed from '../../../img/crossRed.png';
import checkBlue from '../../../img/checkBlue.png';

const UploadRequest = ({ back, addToHistory, request, toggleStage, isOpen, loggedin, approve_request, setapproveReject }) => {
    /*const [isSecuringReportOpen, setisSecuringReportOpen] = useState(false);
    const [isMetadataSecuringReportOpen, setisMetadataSecuringReportOpen] = useState(false);
    const [isAnonymizingReportOpen, setisAnonymizingReportOpen] = useState(false);
    const [image_securing, setisImage_securingReportOpen] = useState(false);
    const [isCleaningReportOpen, setisCleaningReportOpen] = useState(false);*/
    const [isDeleteVerification, setisDeleteVerification] = useState(false);
    const navigate = useNavigate();

    const approveRequest = async () => {
        try {
            BackendConnector.approve_request(request.request_id);
            setapproveReject(!approve_request);
        } catch (error) {
            console.error(error);
        }
    }

    const rejectRequest = async () => {
        try {
            BackendConnector.reject_request(request.request_id);
            setapproveReject(!approve_request);
        } catch (error) {
            console.error(error);
        }
    }

    const statusImages = {
        'not_started': not_started,
        'in_progress': in_progress,
        'done': done,
        'failed': failed,
    };

    const handleToDatasetClick = () => {
        let page;
        if (request.uploading?.status === 'done') page = `/dataset/${request.request_id}`;
        else page = `/requestPreview/${request.request_id}`;

        addToHistory(page);
        navigate(page);
    }
    
    const handleEditClick = () => {
        const fetchDatasetPreview = async () => {
            try {
                const data = await BackendConnector.preview(request.request_id);
                addToHistory('/editDataset1');
                navigate('/editDataset', { state: data });
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchDatasetPreview();
      };
      
    const handleDeleteClick = () => {
        setisDeleteVerification(true);
    }

    return (
        <div id='datasetListItem'>
            <div id='briefDatasetListItem' onClick={() => toggleStage(request.request_id)}>
                <div className='rowTitleAndStatus'>
                    <p id='uploadedDatasetTitleList' onClick={handleToDatasetClick}>{request.dataset_title}</p>
                    {request.visibility === 'private' && <div className='lightBlueButton' id='visibilityLabel'>Приватный</div>}
                    <RequestStatus request={request}/>
                </div>
                {loggedin ?
                <div className='rightSectionList'>
                    {request.admin_approving?.status !== 'done' && request.admin_approving?.status !== 'failed' &&
                    <button className='lightBlueButton' id='requestButton' onClick={
                        (event)=>{
                            event.stopPropagation()
                            approveRequest()
                        }}>
                        Принять
                        <img src={checkBlue} id='requestIcon' />
                    </button>
                    }
                    { request.admin_approving?.status !== 'done' && request.admin_approving?.status !== 'failed' &&
                    <button className='lightRedButton' id='requestButton' onClick={
                        (event)=>{
                            event.stopPropagation()
                            rejectRequest()
                        }}>
                        Отклонить
                        <img src={crossRed} id='requestIcon' />
                    </button>
                    }

                    {request.uploading?.status === 'done' && <button onClick={(event)=>{
                        event.stopPropagation()
                        handleDeleteClick()
                    }}
                     style={{border: 'none'}}><img src={redTrash} id='trashIconList' alt="Удалить" /></button>}

                    <img src={arrowDown} className={isOpen ? 'statusIcon rotate180' : 'statusIcon'} alt="Подробнее о стадиях"/>
                </div>
                :
                <div className='rightSectionList'>
                    {request.uploading?.status === 'done' && <button onClick={(event)=>{
                        event.stopPropagation()
                        handleDeleteClick()
                    }}
                     style={{border: 'none'}}><img src={redTrash} id='trashIconList' alt="Удалить" /></button>}
                    {request.uploading?.status === 'done' && <button onClick={(event)=>{
                        event.stopPropagation();
                        handleEditClick();
                    }} style={{border: 'none'}}><img src={editIcon} id='editIconRequest' alt="Редактировать"/></button>}
                    <button className='whiteBlueButton' id='seeDatasetButton' onClick={handleToDatasetClick}>Смотреть</button>
                    <DeleteVerification onClose={(event)=>{
                        event.stopPropagation();
                        setisDeleteVerification(false)}} isOpen={isDeleteVerification} id={request.request_id} back={back}/>
                    <img src={arrowDown} className={isOpen ? 'statusIcon rotate180' : 'statusIcon'} alt="Подробнее о стадиях"/>
                </div>
            }
            </div>
            {isOpen && request && (
                <Stages request={request} statusImages={statusImages} />
                )}
        </div>
    );
}

export default UploadRequest;