import React, { useState, useEffect } from 'react';
import './Filters.css';
import InputTag from '../InputTag/InputTag';
import { RangeSlider } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const Filters = ({ isOpen, defaultSize, defaultRate, geography_and_places, setGeography, data_type, setData_type, topic, setTopic, application, setApplication,
  authors, setAuthors, sources, setSources, sizeLower, setSizeLower, sizeUpper, setSizeUpper, dateLower, setDateLower, dateUpper, setDateUpper, rateLower, setRateLower, rateUpper, setRateUpper, license, setLicense, }) => {
  dayjs.locale('ru');

  const [author, setAuthor] = useState('');
  const [source, setSource] = useState('');
  
  const handlelicenseChange = (e) => {
    if (e.target.value === 'No license specified') {
      setLicense([]);
      return;
    }
    if (!license.includes(e.target.value)) setLicense([...license, e.target.value]);
};

const addAuthor = (e) => {
  e.preventDefault();
  if (!authors.includes(author)) setAuthors([...authors, author]);
  setAuthor('');
};

const addSource = (e) => {
  e.preventDefault();
  if (!sources.includes(source)) setSources([...sources, source]);
  setSource('');
};
  
function valueLabelFormat(size) {
    if (size === 0) return '0 Б';
    const sizes = ['Б', 'КБ', 'МБ', 'ГБ'];
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const formattedSize = (size / Math.pow(1024, i)).toFixed(3);
    return `${parseFloat(formattedSize)} ${sizes[i]}`;
};

  const [size, setSize] = useState([sizeLower, sizeUpper]);
  const [rate, setRate] = useState([rateLower, rateUpper]);
  const [dateL, setDateL] = useState(dateLower);
  const [dateU, setDateU] = useState(dateUpper);

  useEffect(() => {
    if (dateL) {
      const formattedDate = dayjs(dateL, "DD.MM.YYYY").isValid()
        ? dayjs(dateL, "DD.MM.YYYY").format("DD.MM.YYYY")
        : dayjs(dateL).format("DD.MM.YYYY");
      setDateLower(formattedDate);
    }
    if (dateU) {
      const formattedDate = dayjs(dateU, "DD.MM.YYYY").isValid()
        ? dayjs(dateU, "DD.MM.YYYY").format("DD.MM.YYYY")
        : dayjs(dateU).format("DD.MM.YYYY");
      setDateUpper(formattedDate);
    }
  }, [dateL, dateU]);
  
  useEffect(()=>{
    if (size[0] != sizeLower) setSizeLower(size[0]);
    if (size[1] != sizeUpper) setSizeUpper(size[1]);
  }, [size])

  useEffect(()=>{
    if (sizeLower == defaultSize[0] && sizeUpper == defaultSize[1]) setSize(defaultSize)
  }, [sizeLower, sizeUpper])

  useEffect(()=>{
    if (rate[0] != rateLower) setRateLower(rate[0]);
    if (rate[1] != rateUpper) setRateUpper(rate[1]);
  }, [rate])

  useEffect(()=>{
    if (rateLower == defaultRate[0] && rateUpper == defaultRate[1]) setRate(defaultRate)
  }, [rateLower, rateUpper])

  if (!isOpen) {
    return null;
  }

  return (
      <div className="modal-content-filters">
        <div>
            <InputTag noTags='yes' label="География данных" tags={geography_and_places} setTags={setGeography} />
            <InputTag noTags='yes' label="Тип данных" tags={data_type} setTags={setData_type} />
            <InputTag noTags='yes' label="Тема" tags={topic} setTags={setTopic} />
            <InputTag noTags='yes' label="Сценарии применения" tags={application} setTags={setApplication} />

            <div id='inputTagsFilter'>
              <p id='tagTypeLabel'>Автор</p>
              <div className="input-container">
                <form onSubmit={addAuthor} className='row'>
                  <input
                    type="search"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    placeholder="Введите никнейм"
                    maxLength={1000}
                  />
                  <button type='submit' className='whiteBlueButton' id='addTagButton'>Добавить</button>
                </form>
              </div>
            </div>

            <div id='inputTagsFilter'>
              <p id='tagTypeLabel'>Источник</p>
              <div className="input-container">
                <form onSubmit={addSource} className='row'>
                  <input
                    type="search"
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                    placeholder="Введите источник"
                    maxLength={1000}
                  />
                  <button type='submit' className='whiteBlueButton' id='addTagButton'>Добавить</button>
                </form>
              </div>
            </div>
            <div id='inputTagsFilter'>
              <p id='tagTypeLabel' style={{marginTop: '13px'}}>Лицензия</p>
              <select value={license} style={{width: '230px', marginRight: '10px'}} onChange={handlelicenseChange} className="selectionInput">
                  <option value="No license specified">Не указана</option>
                  <option value="Public Domain">Public Domain</option>
                  <option value="PDDL">Open Data Commons Public Domain Dedication and license - PDDL</option>
                  <option value="CC-BY">Creative Commons Attribution 4.0 International CC-BY</option>
                  <option value="CDLA Permissive-2.0">Community Data license Agreement – CDLA Permissive-2.0</option>
                  <option value="ODC-BY">Open Data Commons Attribution license - ODC-BY</option>
                  <option value="CC-BY-SA">Creative Commons Attribution-ShareAlike 4.0 International - CC-BY-SA</option>
                  <option value="CDLA-Sharing-1.0">Community Data license Agreement – CDLA-Sharing-1.0</option>
                  <option value="ODC-ODbL">Open Data Commons Open Database license - ODC-ODbL</option>
                  <option value="CCPDD">Creative Commons Public Domain Dedication</option>
                  <option value="CC BY-NC">Creative Commons Attribution-NonCommercial 4.0 International - CC BY-NC</option>
                  <option value="CC BY-NC-SA">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International - CC BY-NC-SA</option>
                  <option value="Additional license">Additional license</option>
              </select>
            </div>
            <div id='inputTagsFilter'>
              <p id='tagTypeLabel'>Дата обновления</p>
              <p style={{margin: '7px 15px 0px 0px'}}>с</p>
              <DateInput
                value={dateL}
                onChange={setDateL}
                placeholder="Выберите дату"
                valueFormat="DD.MM.YYYY"
                locale="ru"
                hideOutsideDates
                maxDate={new Date()}
                className='multiselect'
                classNames={{input: 'multiselectInput'}}
                styles={{
                  calendarHeader: {
                    textAlign: 'center',
                  },
                  day: {
                    width: '30px',
                    height: '30px',
                    textAlign: 'center',
                    margin: '0'
                  },
                  weekday: {
                    padding: '5px',
                    margin: '0',
                  },
                  calendar: {
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed',
                  },
                  calendarHeader: {
                    fontWeight: '800',
                    textAlign: 'center',
                    margin: '5px 0px'
                  }
                }}
                />
                <p style={{margin: '7px 15px 0px 0px'}}>до</p>
                <DateInput
                value={dateU}
                onChange={setDateU}
                placeholder="Выберите дату"
                valueFormat="DD.MM.YYYY"
                locale="ru"
                hideOutsideDates
                minDate={dateL}
                maxDate={new Date()}
                className='multiselect'
                classNames={{input: 'multiselectInput'}}
                styles={{
                  calendarHeader: {
                    textAlign: 'center',
                  },
                  day: {
                    width: '30px',
                    height: '30px',
                    textAlign: 'center',
                    margin: '0'
                  },
                  weekday: {
                    padding: '5px',
                    margin: '0',
                  },
                  calendar: {
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed',
                  },
                  calendarHeader: {
                    fontWeight: '800',
                    textAlign: 'center',
                    margin: '5px 0px'
                  }
                }}
                />
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <p id='tagTypeLabel'>Размер</p>
              <div style={{ flex: 0.5 }}>
                <RangeSlider
                  mt={50}
                  step={2**20}
                  min={defaultSize[0]}
                  max={defaultSize[1]}
                  value={size}
                  defaultValue={defaultSize}
                  onChange={setSize}
                  label={valueLabelFormat}
                  labelAlwaysOn
                  minRange={2**29}
                  classNames={{
                      root: 'sliderRoot',
                      label: 'sliderLabel',
                      track: 'sliderTrack',
                      mark: 'sliderMark',
                      markLabel: 'sliderMarkLabel',
                  }}
              />
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <p id='metadataLabel' style={{fontWeight: '500', width: '200px'}}>Рейтинг</p>
              <div style={{ flex: 0.5 }}>
                <RangeSlider
                  mt={50}
                  min={defaultRate[0]}
                  max={defaultRate[1]}
                  step={1}
                  value={rate}
                  onChange={setRate}
                  defaultValue={defaultRate}
                  labelAlwaysOn
                  minRange={0}
                  classNames={{root: 'sliderRoot', label: 'sliderLabel', track: 'sliderTrack', mark: 'sliderMark', markLabel: 'sliderMarkLabel'}}
                />
              </div>
            </div>
        </div>
      </div>
  );
}

export default Filters;
