import React from 'react';
import './AuthAlert.css';

const SSO_LOGIN_URL = process.env.REACT_APP_SSO_LOGIN_URL; // URL авторизации SSO
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID; // Ваш Client ID
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI; // Куда вернётся пользователь после авторизации

/**
 * Функция для редиректа пользователя на страницу SSO для входа.
 */
const redirectToLogin = () => {
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    scope: 'openid',
    state: 'state_value', // Добавьте здесь уникальное значение для защиты от CSRF
  });

  window.location.href = `${SSO_LOGIN_URL}/auth?${params.toString()}`;
};

/**
 * Функция для редиректа пользователя на страницу SSO для регистрации.
 * Если ваша система SSO поддерживает регистрацию, нужно уточнить её URL.
 */
const redirectToRegister = () => {
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    scope: 'openid',
    state: 'state_value',
  });

  window.location.href = `${SSO_LOGIN_URL}/register?${params.toString()}`;
};

const AuthAlert = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="modal-overlay">
      <div className="modal-content-auth">
        <div className="modal-header">
          <h3 id="authModalLabel">Войдите или зарегистрируйтесь</h3>
          <button className="modal-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="buttonContainer">
          <button
            className="lightBlueButton"
            onClick={redirectToLogin}
            style={{ padding: '10px 40px', marginRight: '20px' }}
          >
            Войти
          </button>
          <button className="whiteBlueButton" onClick={redirectToRegister}>
            Зарегистрироваться
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthAlert;
