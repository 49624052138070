import React, { useState, useEffect } from 'react';
import { EducationLogo } from './EducationLogo';
import { BackendConnector } from '../../components'
import './Header.css';
import Login from '../../Login/Login';

const Header = ({ addToHistory, loggedin, LOGGEDIN, SETLOGGEDIN }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [keyCombo, setKeyCombo] = useState({ Alt: false, Meta: false });
  
  useEffect(()=>{
    if (!LOGGEDIN || !loggedin) localStorage.removeItem('token');
  }, [LOGGEDIN, loggedin])

  const handleKeyDown = (event) => {
    const updatedCombo = { ...keyCombo };

    if (event.key === 'Alt') updatedCombo.Alt = true;
    if (event.key === 'Meta') updatedCombo.Meta = true;

    setKeyCombo(updatedCombo);
  };

  const handleKeyUp = (event) => {
    const updatedCombo = { ...keyCombo };

    if (event.key === 'Alt') updatedCombo.Alt = false;
    if (event.key === 'Meta') updatedCombo.Meta = false;

    setKeyCombo(updatedCombo);
  };

  const handleLoginClick = () => {
    if (keyCombo.Alt && keyCombo.Meta) setShowAlert(true);
  };

  useEffect(() => {
    if (loggedin) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [loggedin, keyCombo]);

  const handleOk = async () => {
    const log_in = async () => {
      const data = await BackendConnector.log_in(inputValue);
      if (data !== null) {
        SETLOGGEDIN(true);
      }
  };

    if (!LOGGEDIN) await log_in();
    setInputValue('');
    setShowAlert(false);
  };

  const handleClose = () => {
    setShowAlert(false);
    setInputValue('');
  };

  return (
    <>
      <div className='headerContainer'>
        <EducationLogo />
        <div className='productButtons'></div>
        <div onClick={() => handleLoginClick()}>
          <Login addToHistory={addToHistory} LOGGEDIN={LOGGEDIN}/>
        </div>
      </div>

      {showAlert && (
        <div className="alertBackground">
          {!LOGGEDIN ?
             <div className="alertBox">
              <form><input
                type="password"
                className="alertInput"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                autoFocus
              /></form>
              <div className="alertButtons">
                <button onClick={handleOk} className="lightBlueButton" style={{padding: '5px 15px', margin: '0'}}>ОК</button>
                <button onClick={handleClose} className="lightRedButton" style={{padding: '5px 15px', margin: '0'}}>Закрыть</button>
              </div>
            </div>
            :
            <div className="alertBox">
                <button onClick={()=>{
                  SETLOGGEDIN(false);
                  setShowAlert(false);}} className="lightBlueButton" style={{padding: '5px 15px', margin: '0'}}>Выйти</button>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default Header;
