import React, { useState, useEffect } from 'react';
import { Tabs } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Header, Icon, RequestList } from '../../components';
import './UploadRequests.css';
import plusWhiteIcon from '../../img/plusWhite.png';
import back from '../../img/back.png';

const UploadRequests = ({loggedin, addToHistory}) => {
    const navigate = useNavigate();
    const [view, setView] = useState('list');
    useEffect(() => {
        document.title = `Мои датасеты`;
        addToHistory('/uploadRequests');
    }, []);
    
    const goToUpload = () => {
        addToHistory('/upload')
        navigate('/upload');
    }

    const goMain = () => {
        addToHistory('/');
        navigate('/')
    }
    
    return (
        <div id='uploadRequests'>
            <div className='back'onClick={()=>{goMain()}} style={{marginBottom: '40px'}}>
                <Icon image={back} />
                <button style={{whiteSpace: 'nowrap'}}>На главную</button>
            </div>
            <div className='rowSpaceBetween' id='pageLabel' style={{marginBottom: '20px'}}>
                <h2>{loggedin? "Заявки на загрузку" : "Мои датасеты"}</h2>
                <div className='row'>
                    {/* <select className='selectionInput' onChange={(e)=>{setView(e.target.value)}} defaultValue='list' id='datasetForm'>
                        <option value='list'>Список</option>
                        <option value='cards'>Карточки</option>
                    </select> */}
                    <button className='lightBlueButton' id='uploadDatasetButton' style={{padding: '10px 20px', textWrap: 'nowrap'}}  onClick={goToUpload}>+ Новый датасет</button>
                    <button className='blueButton' id='newDatasetButtonSmall' onClick={goToUpload}>
                        <img src={plusWhiteIcon} id='plusIcon' alt="Добавить новый датасет" />
                    </button>
                </div>
            </div>
            
            
                <Tabs defaultValue={loggedin ? "approve" : "in_progress"} classNames={{tabLabel: 'tabLabel', list: 'tabList', tab: 'tab'}}>
                    {loggedin ?
                        <Tabs.List>
                            <Tabs.Tab value="approve">
                            Для рассмотрения
                            </Tabs.Tab>
                            <Tabs.Tab value="all_uploading">
                            Загружаются
                            </Tabs.Tab>
                            <Tabs.Tab value="all_stopped">
                            Остановленные
                            </Tabs.Tab>
                            <Tabs.Tab value="all_uploaded">
                            Загруженные
                            </Tabs.Tab>
                        </Tabs.List>
                        :
                        <Tabs.List>
                            <Tabs.Tab value="in_progress">
                            Загружаются
                            </Tabs.Tab>
                            <Tabs.Tab value="stopped">
                            Загрузка приостановлена
                            </Tabs.Tab>
                            <Tabs.Tab value="uploaded">
                            Загруженные
                            </Tabs.Tab>
                        </Tabs.List>}

                    {!loggedin &&
                    <Tabs.Panel value="in_progress" pt="xs">
                        <RequestList loggedin={loggedin} type='uploading'view={view} addToHistory={addToHistory} />
                    </Tabs.Panel>}

                    {!loggedin &&
                        <Tabs.Panel value="stopped" pt="xs">
                        <RequestList loggedin={loggedin} type='failed' view={view} addToHistory={addToHistory} />
                    </Tabs.Panel>}

                    {!loggedin &&
                        <Tabs.Panel value="uploaded" pt="xs">
                        <RequestList loggedin={loggedin} type='uploaded' view={view} addToHistory={addToHistory} />
                    </Tabs.Panel>}

                    {loggedin &&
                    <Tabs.Panel value="approve" pt="xs">
                        <RequestList loggedin={loggedin} type='approve'view={view} addToHistory={addToHistory} />
                    </Tabs.Panel>}
                    {loggedin &&
                    <Tabs.Panel value="all_uploading" pt="xs">
                        <RequestList loggedin={loggedin} type='all_uploading'view={view} addToHistory={addToHistory} />
                    </Tabs.Panel>}
                    {loggedin &&
                    <Tabs.Panel value="all_uploaded" pt="xs">
                        <RequestList loggedin={loggedin} type='all_uploaded'view={view} addToHistory={addToHistory} />
                    </Tabs.Panel>}
                    {loggedin &&
                    <Tabs.Panel value="all_stopped" pt="xs">
                        <RequestList loggedin={loggedin} type='all_stopped'view={view} addToHistory={addToHistory} />
                    </Tabs.Panel>}
                </Tabs>
        </div>
    );
}

export default UploadRequests;