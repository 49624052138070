import React, { useState, useEffect } from 'react';
import './InputTag.css';
import BackendConnector from '../BackendConnector';

const InputTag = ({ label, tags, setTags, noTags }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [errorSuggestions, seterrorSuggestions] = useState(false);

  useEffect(()=>{
    seterrorSuggestions(false);
  })

  const category = {
    "География данных": "geography_and_places",
    "Тип данных": "data_type",
    "Тема": "topic",
    "Сценарии применения": "applications",
  }

  const placeholder = {
    "География данных": "Введите страну",
    "Тип данных": "Введите тип данных",
    "Тема": "Введите тему",
    "Сценарии применения": "Укажите применение",
    "Автор": "Введите никнейм",
    "Источник": "Введите источник"
  }

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!category[label]) return;
      if (inputValue.length > 0) {
        try {
          const fetchedSuggestions = await BackendConnector.fetchSuggestions(category[label], inputValue);
          setSuggestions(fetchedSuggestions);
        } catch (error) {
          seterrorSuggestions(true);
        }
      } else {
        setSuggestions([]);
      }
    };
    fetchSuggestions();
  }, [inputValue]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleTagSelect = (tag) => {
    if (!tags.includes(tag)) {
      setTags([...tags, tag]);
    }
    setInputValue('');
    setSuggestions([]);
  };

  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  };

  return (
    <div>
      <div id='inputTagsFilter'>
      <p id='tagTypeLabel'>{label}</p>
        <div className="input-container">
          <input
            type="search"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder[label]}
            maxLength={1000}
          />
        </div>
        
        <div className='tagsDisplay'>
          {suggestions.length > 0 && (
            <span className='rowTags'>
              {suggestions.map((suggestion, index) => (
                <div id='tagFilterSuggestion' className={label==='География данных' && suggestion !== 'глобальная' ? 'capitalize' : ''} key={`${suggestion}-${index}`} onClick={() => handleTagSelect(suggestion)}>
                  {suggestion}
                </div>
              ))}
            </span>
          )}
          {!noTags && tags.map((tag, index) => (
            <div id='tag' className={label==='География данных' && tag !== 'глобальная' ? 'capitalize lightBlueButton' : 'lightBlueButton'} key={`${tag}-${index}`}>
              {tag}
              <button id='deleteTagButton' onClick={() => handleRemoveTag(index)}>&times;</button>
            </div>
          ))}
        </div>
      </div>
      {errorSuggestions && <p className='warning' style={{marginBottom: '15px'}}>Прозошла ошибка с загрузкой предложений тегов. Повторите попытку позже.</p>}
    </div>
  );
};

export default InputTag;
